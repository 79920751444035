import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ScenarioDto } from '../model/scenarioDto';
import { ClearingDataFormArrayDto } from '../model/clearingDataFormArrayDto';
import { ClearingDataAttribute } from '../model/clearingDataAttribute';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { TargetStateDto } from '../model/targetStateDto';
import { PlatformPartner } from '../model/platformPartner';


@Injectable()
export class ClearingTicketCustomService {
  protected baseConfigPath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) baseConfigPath: string, @Optional() configuration: Configuration) {
    if (baseConfigPath) {
      this.baseConfigPath = baseConfigPath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.baseConfigPath = baseConfigPath || configuration.basePath || this.baseConfigPath;
    }
  }


  /**
   * List Scenarios
   * This operation lists all Scenarios with corresponding subscenarios.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listScenarios(observe?: 'body', reportProgress?: boolean): Observable<Array<ScenarioDto>>;
  public listScenarios(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScenarioDto>>>;
  public listScenarios(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScenarioDto>>>;
  public listScenarios(observe: any = 'body', reportProgress = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ScenarioDto>>('get', `${this.baseConfigPath}/troubleTicket/scenarios`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve a Clearing Ticket Possible target states by ID
   * This operation retrieves a Clearing Ticket Possible target states
   * @param id Identifier of the ClearingTicket
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listPossibleTargetStates(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetStateDto>>;
  public listPossibleTargetStates(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetStateDto>>>;
  public listPossibleTargetStates(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetStateDto>>>;
  public listPossibleTargetStates(id: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listPossibleTargetStates.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<TargetStateDto>>('get', `${this.baseConfigPath}/troubleTicket/${encodeURIComponent(String(id))}/available-states`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve a Clearing Ticket Clearing Data  by Scenario ID
   * This operation retrieves a  Clearing Ticket Clearing Data
   * @param id Identifier of the ClearingTicket Scenario
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listClearingDataByScenarioId(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ClearingDataAttribute>>;
  public listClearingDataByScenarioId(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClearingDataAttribute>>>;
  public listClearingDataByScenarioId(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClearingDataAttribute>>>;
  public listClearingDataByScenarioId(id: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listClearingDataByScenarioId.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ClearingDataAttribute>>('get', `${this.baseConfigPath}/troubleTicket/scenarios/${encodeURIComponent(String(id))}/clearingData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve a Clearing Ticket Clearing Data Form Array  by Scenario ID for specific ClearingDataAttribute
   * This operation retrieves a  Clearing Ticket Clearing Data for specific attribute
   * @param scenarioId Identifier of the ClearingTicket Scenario
   * @param attributeName Identifier of the Specific ClearingDataAttribute
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingDataFormArrayDto>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingDataFormArrayDto>>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingDataFormArrayDto>>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (scenarioId === null || scenarioId === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listClearingDataByScenarioIdAndAttributeName.');
    }

    if (attributeName === null || attributeName === undefined) {
      throw new Error('Required parameter attributeName was null or undefined when calling listClearingDataByScenarioIdAndAttributeName.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ClearingDataFormArrayDto>>('get', `${this.baseConfigPath}/troubleTicket/scenarios/${encodeURIComponent(String(scenarioId))}/${encodeURIComponent(String(attributeName))}/clearingData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public listPlatformPartnerConfiguration() {
    return this.httpClient.request<Array<PlatformPartner>>('get', `${this.baseConfigPath}/platformPartner`);
  }

  public retrievePlatformPartnerConfiguration(id: string) {
    return this.httpClient.request<PlatformPartner>('get', `${this.baseConfigPath}/platformPartner/${id}`);
  }

  public createPlatformPartnerConfiguration(platformPartnerCreate: PlatformPartner) {
    return this.httpClient.request<PlatformPartner>('post', `${this.baseConfigPath}/platformPartner`, {
      body: platformPartnerCreate
    });
  }

  public updatePlatformPartnerConfiguration(id: number, platformPartnerUpdate: PlatformPartner) {
    return this.httpClient.request<PlatformPartner>('patch', `${this.baseConfigPath}/platformPartner/${id}`, {
      body: platformPartnerUpdate
    });
  }
}
